import hotkeys from "hotkeys-js";
import { useCallback, useEffect } from "react";
export function useHotkeys(keys, callback, options, deps) {
    const memoisedCallback = useCallback(callback, deps || []);
    useEffect(() => {
        if (options && options.enableOnTags) {
            hotkeys.filter = ({ target, srcElement }) => {
                // @ts-ignore
                const targetTagName = (target && target.tagName) || (srcElement && srcElement.tagName);
                return Boolean(targetTagName && options.enableOnTags && options.enableOnTags.includes(targetTagName));
            };
        }
        if (options && options.filter)
            hotkeys.filter = options.filter;
        hotkeys(keys, options || {}, memoisedCallback);
        return () => hotkeys.unbind(keys, memoisedCallback);
    }, [memoisedCallback, options]);
}
